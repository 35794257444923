<template>
	<div>
		<v-row class="pt-3">
			<v-col>
				<h2 class="text-h4">Notificaciones</h2>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="4">
				<NotificationForm />
			</v-col>

			<v-col cols="8">
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th
									v-for="(item, index) in headers"
									:key="`head-${index}`"
								>
									{{ item.text }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(row, indexRow) in notificationsShow"
								:key="`body-${indexRow}`"
							>
								<td
									v-for="(col, indexCol) in headers"
									:key="`${indexRow}-col-${indexCol}`"
								>
									<template
										v-if="col.value == 'channel_code'"
									>
										<v-chip
											small
											class="ma-2"
											v-for="(
												name, indexNameChannel
											) in row[col.value]"
											:key="indexNameChannel"
										>
											{{ name }}
										</v-chip>
									</template>

									<template v-else-if="col.value == 'id'">
										<v-btn
											icon
											color="error"
											@click="
												deleteNotify(row[col.value])
											"
										>
											<v-icon
												>mdi-trash-can-outline</v-icon
											>
										</v-btn>
									</template>

									<template v-else>
										<span
											:class="
												col.value == 'area' &&
												row[col.value] ==
													'Usuarios tipo: Regante'
													? 'acm-irrigator'
													: ''
											"
										>
											{{ row[col.value] }}
										</span>

										<a
											class="acm-download-file"
											v-if="
												col.value === 'message' &&
												row.attached
											"
											:href="row.attached"
											target="_blank"
											rel="noreferrer"
											>Descargar archivo</a
										>
									</template>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import NotificationForm from '@/components/NotificationForm';

	export default {
		name: 'acm-notification',

		data() {
			return {
				messageType: [
					{
						value: 'information',
						name: 'Información',
					},
					{
						value: 'urgent',
						name: 'Urgente',
					},
				],

				headers: [
					{ text: 'Tipo', value: 'type' },
					{ text: 'Mensaje', value: 'message' },
					{ text: 'Activación', value: 'activation' },
					{
						text: 'Area de la Asociación',
						value: 'area',
					},
					{ text: 'Canal', value: 'channel_code' },
					{ text: 'Visto por', value: 'total' },
					{ text: '', value: 'id' },
				],
			};
		},

		components: {
			NotificationForm,
		},

		computed: {
			...mapState('userControl', ['associationAreas']),
			...mapState('irrigatorControl', ['channels']),
			...mapState('systemNotification', ['notifications']),

			notificationsShow() {
				let items = this.$utils.deepCopy(this.notifications);

				items = items.map(item => {
					const areaCode = item.association_area_code;
					/* Actualizo el texto de type para que se lea */
					item.type = this.messageType.reduce(
						(acc, curr) =>
							curr.value == item.type ? curr.name : acc,
						''
					);

					item.activation = this.$utils
						.moment(item.activation)
						.format('DD/MM/YYYY hh:mm a');

					/* Encuentro el nombre del canal para mostrarlo en la tabla */
					if (item.channel_code && item.channel_code.length) {
						item.channel_code = item.channel_code.map(cod => {
							let name = this.channels.filter(
								cha => cha.code == cod
							);

							name = name.length ? name[0].name : '';

							return name;
						});
					} else {
						item.channel_code = ['Todos los canales'];
					}

					if (!areaCode || !areaCode.length) {
						item.area = 'Usuarios tipo: Regante';
					} else {
						item.area =
							'Usuarios tipo: ' +
							this.associationAreas.filter(
								acm => acm.code == areaCode
							)[0].name;
					}

					if (item.users_permissions_user) {
						item.message =
							item.message +
							` (Creado por: ${item.users_permissions_user.username})`;
					}

					if (item.attached && item.attached.length === 0)
						delete item.attached;

					if (item.attached && item.attached.length > 0) {
						item.attached =
							process.env.VUE_APP_API + item.attached[0].url;
					}

					return item;
				});

				return items;
			},
		},

		mounted() {
			this.loadNotifications({ next: 0, count: 0 });
		},

		methods: {
			...mapActions('systemNotification', [
				'loadNotifications',
				'deleteNotification',
			]),

			deleteNotify(id) {
				this.deleteNotification(id).then(() => {
					this.loadNotifications({ next: 0, count: 0 });
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.acm-irrigator {
		color: rgb(104, 104, 255);
	}

	.acm-download-file {
		display: block;
	}
</style>
