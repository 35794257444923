<template>
	<v-card>
		<v-card-title primary-title> Mensaje a enviar </v-card-title>
		<v-card-text>
			<v-form ref="notificationForm" v-model="valid" lazy-validation>
				<v-radio-group v-model="messageFor" row>
					<v-radio
						label="Regantes"
						value="irrigator"
						required
					></v-radio>
					<v-radio label="Celadores" value="watchman"></v-radio>
					<v-radio
						label="Jefes de Sección"
						value="sectionm"
					></v-radio>
				</v-radio-group>

				<v-checkbox
					v-if="messageFor == 'watchman'"
					v-model="checkWatchmanForm"
					label="Escoger los celadores"
				></v-checkbox>

				<v-autocomplete
					v-if="messageFor == 'sectionm'"
					v-model="watchmanForm"
					:rules="watchmanFormRules"
					:items="sectionm"
					item-value="id"
					item-text="name"
					chips
					small-chips
					label="Jefe de sección"
					multiple
					solo
					persistent-hint
				></v-autocomplete>

				<v-autocomplete
					v-if="checkWatchmanForm"
					v-model="watchmanForm"
					:rules="watchmanFormRules"
					:items="watchmans"
					item-value="id"
					item-text="name"
					chips
					small-chips
					label="Celador"
					multiple
					solo
					persistent-hint
				></v-autocomplete>

				<v-autocomplete
					v-if="!checkWatchmanForm && messageFor != 'sectionm'"
					v-model="channelsForm"
					:rules="channelsFormRules"
					:items="channelsList"
					item-value="code"
					item-text="name"
					chips
					small-chips
					label="Canal"
					multiple
					solo
					persistent-hint
				></v-autocomplete>

				<v-select
					v-model="messageTypeForm"
					:rules="messageTypeRules"
					:items="messageType"
					label="Tipo de mensaje"
					item-value="value"
					item-text="name"
					hint="Sirve para identificar qué tipo de icono y color usar para el mensaje."
					persistent-hint
					required
					solo
				></v-select>

				<v-menu
					v-model="menuActivationForm"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="activationForm"
							label="Fecha de activación"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							v-bind="attrs"
							v-on="on"
							required
							autocomplete="off"
							solo
						></v-text-field>
					</template>
					<v-date-picker
						v-model="activationForm"
						no-title
						@input="menuActivationForm = false"
					></v-date-picker>
				</v-menu>

				<v-menu
					v-model="menuActivationTimeForm"
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="activationTimeForm"
							label="Hora"
							prepend-inner-icon="mdi-clock-time-four-outline"
							readonly
							required
							v-bind="attrs"
							v-on="on"
							solo
						></v-text-field>
					</template>
					<v-time-picker
						v-if="menuActivationTimeForm"
						v-model="activationTimeForm"
						full-width
						ampm-in-title
						@click:minute="menuActivationTimeForm = false"
						:allowed-minutes="minutes"
					></v-time-picker>
				</v-menu>

				<div style="margin-bottom: 1rem">
					<markdown-editor
						v-model="messageForm"
						toolbar="bold italic link preview"
					></markdown-editor>
					<div>
						{{ messageFormHint }}
					</div>
					<v-textarea
						class="texto-notificacion"
						id="texto-notificacion"
						v-model="messageForm"
						:rules="[v => !!v || 'Es requerido.']"
						:hint="messageFormHint"
						:maxlength="messageFormLength"
					></v-textarea>
				</div>

				<v-file-input
					solo
					prepend-icon=""
					prepend-inner-icon="mdi-paperclip"
					v-model="fileToUpload"
					truncate-length="15"
					:disabled="isLoading"
					placeholder="Buscar archivo..."
				></v-file-input>

				<v-btn
					color="primary"
					:loading="fileToUpload && isLoading"
					@click="saveNotify()"
					>Enviar</v-btn
				>
			</v-form>
		</v-card-text>
	</v-card>
</template>

<script>
	import { mapActions, mapState } from 'vuex';

	export default {
		data() {
			return {
				valid: true,

				messageFor: 'irrigator',
				messageTypeForm: 'information',
				messageForm: '',
				channelsForm: ['todos'],
				channelsFormRules: [v => !!v || 'Es requerido.'],
				checkWatchmanForm: false,
				watchmanForm: '',
				watchmanFormRules: [v => !!v || 'Es requerido.'],

				activationForm: this.$utils.moment().format('YYYY-MM-DD'),
				menuActivationForm: false,
				activationTimeForm: this.$utils
					.moment()
					.add(1, 'hours')
					.format('HH:00'),
				menuActivationTimeForm: false,

				messageType: [
					{
						value: 'information',
						name: 'Información',
					},
					{
						value: 'urgent',
						name: 'Urgente',
					},
				],
				messageTypeRules: [v => !!v || 'Es requerido.'],
				messageFormLength: 1000,

				fileToUpload: null,
			};
		},

		watch: {
			channelsForm: {
				handler(newvalue) {
					/* Todo está seleccionado, no se permite escoger a otras opciones */
					if (newvalue.length > 1 && newvalue.includes('todos'))
						this.channelsForm = ['todos'];
				},
				deep: true,
			},

			messageFor: {
				handler() {
					this.checkWatchmanForm = false;
					this.channelsForm = ['todos'];
					this.watchmanForm = '';
				},
				deep: true,
			},

			checkWatchmanForm: {
				handler() {
					this.channelsForm = '';
					this.watchmanForm = '';
				},
				deep: true,
			},
		},

		computed: {
			...mapState('irrigatorControl', ['channels']),
			...mapState('userControl', ['watchmans', 'sectionm']),
			...mapState('systemNotification', ['isLoading']),

			messageFormHint() {
				let count = this.messageFormLength - this.messageForm.length;
				return count
					? `Dispones de ${count} caracteres`
					: 'Llegastes al máximo de caracteres permitidos';
			},

			channelsList() {
				let list = this.$utils.deepCopy(this.channels);
				list.unshift({ code: 'todos', name: 'Todos los canales' });

				return list;
			},

			toSend() {
				let notify = {
					message: this.messageForm,
					type: this.messageTypeForm,
					activation: this.$utils
						.moment(
							`${this.activationForm} ${this.activationTimeForm}`,
							'YYYY-MM-DD HH:mm'
						)
						.utc()
						.toString(),
				};

				if (this.watchmanForm.length) {
					notify.watchman = this.watchmanForm;
					notify.association_area_code = 'watchman';
				}

				if (
					this.channelsForm.length &&
					!this.channelsForm.includes('todos')
				)
					notify.channel_code = this.channelsForm;

				if (this.messageFor != 'irrigator')
					notify.association_area_code = this.messageFor;

				notify.viewed_by_irrigator = [];
				notify.viewed_by_acm = [];

				if (this.fileToUpload) {
					var formdata = new FormData();
					formdata.append(
						'files.attached',
						this.fileToUpload,
						this.fileToUpload.name
					);
					formdata.append('data', JSON.stringify(notify));

					return formdata;
				}

				return notify;
			},

			minutes() {
				let total = 0,
					minutes = [];

				while (total < 60) {
					minutes.push(total);
					total += 10;
				}

				return minutes;
			},
		},

		mounted() {
			this.loadWatchmanList();
		},

		methods: {
			...mapActions('userControl', ['loadWatchmanList']),

			...mapActions('systemNotification', [
				'activeAlert',
				'loadNotifications',
				'addNotification',
			]),

			saveNotify() {
				if (this.$refs.notificationForm.validate()) {
					this.addNotification(this.toSend).then(() => {
						this.messageFor = 'irrigator';
						this.messageTypeForm = 'information';
						this.messageForm = '';
						this.channelsForm = ['todos'];
						this.activationForm = this.$utils
							.moment()
							.format('YYYY-MM-DD');
						this.checkWatchmanForm = false;
						this.watchmanForm = '';
						this.loadNotifications({ next: 0, count: 0 });
						this.fileToUpload = null;
					});
				} else {
					this.activeAlert({
						type: 'error',
						message: 'Revisar que el formulario esté completo.',
					});
				}
			},
		},
	};
</script>

<style lang="scss">
	.texto-notificacion {
		padding-top: 0px;
		.v-input__slot {
			display: none;
		}
	}
</style>
