var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pt-3"},[_c('v-col',[_c('h2',{staticClass:"text-h4"},[_vm._v("Notificaciones")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('NotificationForm')],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(item,index){return _c('th',{key:("head-" + index)},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.notificationsShow),function(row,indexRow){return _c('tr',{key:("body-" + indexRow)},_vm._l((_vm.headers),function(col,indexCol){return _c('td',{key:(indexRow + "-col-" + indexCol)},[(col.value == 'channel_code')?_vm._l((row[col.value]),function(name,indexNameChannel){return _c('v-chip',{key:indexNameChannel,staticClass:"ma-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(name)+" ")])}):(col.value == 'id')?[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteNotify(row[col.value])}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]:[_c('span',{class:col.value == 'area' &&
											row[col.value] ==
												'Usuarios tipo: Regante'
												? 'acm-irrigator'
												: ''},[_vm._v(" "+_vm._s(row[col.value])+" ")]),(
											col.value === 'message' &&
											row.attached
										)?_c('a',{staticClass:"acm-download-file",attrs:{"href":row.attached,"target":"_blank","rel":"noreferrer"}},[_vm._v("Descargar archivo")]):_vm._e()]],2)}),0)}),0)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }